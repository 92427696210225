import React, { Fragment, useEffect, useRef, useState } from 'react'
import'./ManageProject.css'
import { Navbar } from '../components/Navbar'
import ProjectTable from '../components/Table/ProjectTable'
import { Button, Col, Row } from 'reactstrap'
import { ModalComp } from './Modal'
import { CreateProject } from './CreateProject'
import { DeleteModalComp } from './DeleteModal'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { activeProject, deleteProject, fetchProject ,fetchTeamLeadProject,deactivateProject} from '../store/UserAction'
import Loader from 'react-loader-advanced';
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import { InfoTableComp } from './InfoTableComp'

export const ManageProject: React.FC = () => {
    const [showModal, setShowModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [rowData, setRowData] = useState({})
    const dispatch=useAppDispatch()
    const [deletedRow, setDeletedRow] = useState()
    const projectList = useAppSelector(state=>state.user.projectList)
    const projectTeamLeadList = useAppSelector(state=>state.user.projectTeamLeadList)
    const getProjectFlag = useAppSelector(state => state.user.getProjectFlag)
    const getProjectTeamLeadFlag = useAppSelector(state => state.user.getProjectTeamLeadFlag)
    const projectUpdated = useAppSelector(state=>state.user.projectUpdated);
    const projectAdded= useAppSelector(state=>state.user.projectAdded);
    const projectDeleted= useAppSelector(state=>state.user.projectDeleted);
    const projectDeactivated= useAppSelector(state=>state.user.projectDeactivated);
    const projectActivated= useAppSelector(state=>state.user.projectActivated);
    const loading = useAppSelector(state=>state.user.loading);
    const errorMsg= useAppSelector(state=>state.user.errorProjectMsg);
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [infoTableData, setInfoTableData] = useState([])
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [activatedRow, setActivatedRow] = useState()
    const [deactivatedRow, setDeactivatedRow] = useState()
    const [showActivateModal, setShowActiveModal] = useState(false)
    const [showDectivateModal, setShowDectiveModal] = useState(false)
    const spinner4 = <BeatLoader style={{display: 'block'}} color='#ffffff' loading={loading} size={15} aria-label="Loading Spinner" />
    const [switchStates, setSwitchStates] = useState(false)

    useEffect(()=>{
      if(errorMsg !== ''){
        if(errorMsg == '401'){
          navigate('/login')
        }
        toast.error('Somthing went wrong', {
          position: "top-right",
        });
      }
      if(getProjectTeamLeadFlag &&  JSON.parse(localStorage.getItem("user"))?.role === 'Team Lead'){
        setData(projectTeamLeadList)
      }
      if(getProjectFlag &&  JSON.parse(localStorage.getItem("user"))?.role === 'admin'){
          setData(projectList)
      }
      if(projectUpdated === true){
        toast.success("Updated project information successfully!", {
          position: "top-right",
        });
        setShowEditModal(false)
      }
      if(projectAdded === true){
        toast.success("Added new project successfully!", {
          position: "top-right",
        });
        setShowModal(false)
      }
      if(projectDeleted === true){
        toast.success("Delete project successfully!", {
          position: "top-right",
        });
        setShowDeleteModal(false)
      } 
      if(projectDeactivated === true){
        toast.success("Deactivated project successfully!", {
          position: "top-right",
        });
        setShowDeleteModal(false)
      } 
      if(projectActivated === true){
        toast.success("Activated project successfully!", {
          position: "top-right",
        });
        setShowDeleteModal(false)
      } 
      if(!getProjectTeamLeadFlag && JSON.parse(localStorage.getItem("user"))?.role === 'Team Lead'){
        dispatch<any>(fetchTeamLeadProject());
        setData(projectTeamLeadList)
      }
      if(!getProjectFlag && JSON.parse(localStorage.getItem("user"))?.role === 'admin'){
          dispatch<any>(fetchProject());
          setData(projectList)
      }
    },[dispatch,getProjectFlag,projectUpdated,projectAdded,projectList,projectTeamLeadList,projectDeleted,getProjectTeamLeadFlag])
   
    const openEditModal = () =>{
       setShowModal(true)
    }
    const closeModal = () =>{
      setSwitchStates(!switchStates);
      setShowModal(false)
      setShowDeleteModal(false)
      setShowEditModal(false)
      setShowInfoModal(false)
      setShowActiveModal(false)
      setShowDectiveModal(false)
  }
  const addTableData = (newData) =>{
    if((!showEditModal)){
      setData([...data,newData])
    }
    else{
      const updatedArr = data.map(
        item => item.id === newData.id ? {...newData} : {...item}
      );
      setData(updatedArr);
    }
    setShowModal(false)
    setShowEditModal(false)
  }
  const handleDelete =(row) =>{
    setShowDeleteModal(true)
    setDeletedRow(row?.original)
  }
  const handleActivate = (row) =>{
    setShowActiveModal(true)
    setActivatedRow(row?.original)
  }
  const handleEdit = (row) =>{
     setShowEditModal(true)
      setRowData(row?.original)
  }
  const handleInfo = (row) =>{
    let arr = [...row.teamMembers ,row.projectLead]
    setShowInfoModal(true)
    setInfoTableData(arr)
 }
  const handleDeleteRow = () =>{
    let id = deletedRow ? deletedRow['id'] : 'null'
    dispatch<any>(deleteProject(id));
    setShowDeleteModal(false)
  }
  const handleActivateRow = () =>{
    let id = activatedRow ? activatedRow['id'] : 'null'
    dispatch<any>(activeProject(id));
    setShowActiveModal(false)
  }
  const handleDeactivateRow = () =>{
    let id = deactivatedRow ? deactivatedRow['id'] : 'null'
    dispatch<any>(deactivateProject(id));
    setShowDectiveModal(false)
  }
  const handleDeactivate = (row) =>{
    setShowDectiveModal(true)
    setDeactivatedRow(row?.original)
 }
let formTemplate = <CreateProject formType={showModal ? 'add' : 'edit'} closeModal={closeModal} rowData={showModal ? {} : rowData} addTableData={addTableData} />
let infoFormTemplate = <InfoTableComp formType={showModal ? 'add' : 'edit'} closeModal={closeModal} rowData={infoTableData} />
   
 return (
    <Fragment>
         <ToastContainer />
        {showModal === true &&
          <ModalComp isOpen={showModal} modalTitle='Add Project Details' id="addProject" modalBody={formTemplate} toggle={closeModal}/>
        }
        {showEditModal === true &&
         <ModalComp isOpen={showEditModal} modalTitle='Edit Project Details' id="addProject" modalBody={formTemplate} toggle={closeModal}/>
        }
        {showInfoModal === true &&
         <ModalComp isOpen={showInfoModal} modalTitle='Team Member Details' id="addProject" modalBody={infoFormTemplate} toggle={closeModal}/>
        }
        {showDectivateModal === true &&
          <DeleteModalComp isOpen={showDectivateModal} toggle={closeModal} handleDelete={handleDeactivateRow} formType='deactivate' modalBody={'Are you sure you want to deactivate project?'}/>
        }
         {showDeleteModal === true &&
          <DeleteModalComp isOpen={showDeleteModal} toggle={closeModal} handleDelete={handleDeleteRow} formType='delete' modalBody={'Are you sure you want to delete the project?'}/>
        }
         {showActivateModal === true &&
          <DeleteModalComp isOpen={showActivateModal} toggle={closeModal} handleDelete={handleActivateRow} formType='activate' modalBody={'Are you sure you want to activate project?'}/>
        }
       <Navbar />
       <div className='projectDetails'>
   
        <Row style={{paddingRight: '13px', paddingLeft: '10px'}}>
            <div className='projectLabel'>
                <Col>
                    <h5>Projects</h5>
                </Col>
               { JSON.parse(localStorage.getItem("user"))?.role === 'admin' &&
                <Col style={{display: 'flex', justifyContent:'end'}}>
                    <Button onClick={openEditModal}>Add project</Button>
                </Col>
               }
            </div>
        </Row>
         <Loader message={spinner4} show={loading}>
          <Row> <div><ProjectTable switchState={switchStates} data={data} tableName='project' infoEventHandler= {handleInfo} editEventHandler={handleEdit} deleteEventHandler={handleDelete} deactivateEventHandler={handleDeactivate} activateEventHandler = {handleActivate}/></div></Row>
         </Loader>
           
       </div>
    </Fragment>
  )
}
