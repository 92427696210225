import React, { Fragment, useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBValidation,
  MDBValidationItem
}
from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './Login.css';
import { useNavigate } from 'react-router-dom';
import CompLogo from "./CompLogo.jpg";
import { ModalComp } from './Modal';
import { ForgotPassword } from './ForgotPasswordForm';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { login } from '../store/LoginAction';
import { ToastContainer, toast } from "react-toastify";
import { FormGroup, Input, Label,Form, Button, FormFeedback } from 'reactstrap'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

 type Props = {
  onLogIn ?: any;
 }

export const Login: React.FC<Props> = ({onLogIn}: Props) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showModal, setShowModal] = useState(false)
  const isUser = useAppSelector(state=>state.login.isUser)
  const dispatch=useAppDispatch()
  const errorMsg= useAppSelector(state=>state.login.error);
  const forgotPassword = useAppSelector(state=>state.user.forgotPassword);
  const errorForgotPassword = useAppSelector(state=>state.user.errorForgotPassword);
  const [showPassword, setShowPassword] = useState(false)

  const handleEmailChange = (event: any) => {
      setEmail(event.target.value);
  };
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };
  useEffect(()=>{
    if(errorMsg !== ''){
      toast.error(`${errorMsg}`, {
        position: "top-right",
      });
    }
    if(errorForgotPassword !== ''){
      toast.error('Somthing went wrong', {
        position: "top-right",
      });
      setShowModal(false)
    }
    if(forgotPassword === true){
      toast.success('Email send successfully', {
        position: "top-right",
      });
      setShowModal(false)
    }
    if(isUser === true){
      let user = JSON.parse(localStorage.getItem("user")) 
      if(user?.role === 'admin'){
       navigate('/manage_project')
     }
     else if(user?.role === 'Team Lead'){
      navigate('/manage_project')
     }
     else{
       navigate('/manage_worklog')
     }
    }
  },[isUser,errorMsg,forgotPassword])

const handleSubmit = (e) =>{
  e.preventDefault()
  if(email && password !== ''){
    let obj = {
      email: email,
      password: password
    }
    dispatch<any>(login(obj))
    // if(email === 'admin@test.com'){
    //   navigate('/manage_project')
    // }
    // else{
    //   navigate('/manage_worklog')
    // }
    
  }
}
const handleForgotPass = () =>{
  setShowModal(true)
}
const closeModal =() =>{
  setShowModal(false)
}
const handleToggle = () =>{
   setShowPassword(true)
}
let formTemplate = <ForgotPassword closeModal={closeModal}/>
return ( 

  // <MDBContainer fluid >
 
  //     {showModal === true &&
  //       <ModalComp isOpen={showModal} modalTitle='Forgot password' id="forgotPass" modalBody={formTemplate} toggle={closeModal}/>
  //     }
  //    <MDBValidation  >
  //    <ToastContainer />
  //      <MDBRow className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
  //        <MDBCol col='12'>
  //           <MDBCard className='bg-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
  //           <Form onSubmit={handleSubmit}>
  //               <MDBCardBody className='p-5 w-100 d-flex flex-column'>
  //                 <h2 className="fw-bold mb-2 text-center" style={{paddingBottom: '10px'}}>
  //                     <img className="logoCss" src={CompLogo} alt="Company Logo" />
  //                 </h2>
  //                   <MDBValidationItem feedback='Please enter the correct email.' invalid >
  //                       <MDBInput  onChange={handleEmailChange} value={email} required style={{margin: 0,paddingLeft: '5px',width: '99%'}} wrapperClass='mb-4 w-100' label='Email address' id='formControlLg' type='email' size="lg"/>
  //                   </MDBValidationItem>
  //                   <MDBValidationItem  feedback='Please enter the password.' invalid>
  //                         <MDBInput autoComplete='new-password' onChange={handlePasswordChange} value={password} required style={{margin: 0, paddingLeft: '5px', width: '99%'}} wrapperClass='w-100' label='Password'  type='password' />
  //                   </MDBValidationItem>
  //                   <hr className="my-4" />
  //                   <MDBRow style={{paddingBottom: 0, paddingLeft: '10px'}}>
  //                       <Button color="link" onClick={handleForgotPass} style={{width: '48%' , marginRight: '10px', textDecoration : 'underline'}}>
  //                         Forgot password
  //                       </Button>
  //                       <Button color='primary' type='submit' style={{width: '48%'}}>
  //                         Login
  //                       </Button>
  //                     </MDBRow>
  //               </MDBCardBody>
  //               </Form>
  //             </MDBCard>
  //         </MDBCol>
  //       </MDBRow>
  //    </MDBValidation>

  //  </MDBContainer>
  <Fragment>
           {showModal === true &&
         <ModalComp isOpen={showModal} modalTitle='Forgot password' id="forgotPass" modalBody={formTemplate} toggle={closeModal}/>
      }
      <ToastContainer />
    <MDBContainer fluid >
    <MDBRow className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
    <MDBCol col='12'>
    <MDBCard className='bg-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
    <MDBCardBody className='p-5 w-100 d-flex flex-column'>
    <h2 className="fw-bold mb-2 text-center" style={{paddingBottom: '10px'}}>
                      <img className="logoCss" src={CompLogo} alt="Company Logo" />
                  </h2>
    <Form  onSubmit={handleSubmit}>
    <MDBValidationItem feedback='Please enter the correct email.' invalid >
                        <MDBInput  onChange={handleEmailChange} value={email} required style={{margin: 0,paddingLeft: '5px',width: '99%'}} wrapperClass='mb-4 w-100' label='Email Address' id='formControlLg' type='email' size="lg"/>
                    </MDBValidationItem>
               
            <MDBValidationItem  feedback='Please enter the password.' invalid>
                <MDBInput onChange={handlePasswordChange}  autoComplete='new-password' value={password} required style={{margin: 0, paddingLeft: '5px', width: '99%', height: '36px'}} wrapperClass='w-100' label='Password'  type={showPassword ? "text" : "password"} />
                {showPassword ? <FaEye id="icon" onClick={() => setShowPassword(false)}/> : <FaEyeSlash id="icon" onClick={() => setShowPassword(true)} />}
            </MDBValidationItem>

                     <hr className="my-4" />
                    <MDBRow style={{paddingBottom: 0, paddingLeft: '10px'}}>
                        <Button color="link" onClick={handleForgotPass} style={{width: '48%' , marginRight: '10px', textDecoration : 'underline'}}>
                          Forgot password
                         </Button>
                       <Button color='primary' type='submit' style={{width: '48%'}}>
                          Login
                         </Button>
                      </MDBRow>
       </Form>
       </MDBCardBody>
       </MDBCard>
       </MDBCol>
       </MDBRow>
       </MDBContainer>
       </Fragment>
  )
}
