import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box} from '@mui/material';
import React from 'react';
import { RiPencilFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import {RiInformationFill } from "react-icons/ri";
import { FaCheckCircle  } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { Col, Row } from 'reactstrap';
import { Tooltip } from 'react-tooltip'
import { resendEmailVerification } from '../../store/UserAction';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import Switch from "react-switch";
import userSlice from '../../store/UserSlice';

type Props = {
 data ?: any;
 editEventHandler ?: any;
 deleteEventHandler ?: any;
 activateEventHandler ?: any;
 deactivateEventHandler ?: any;
 tableName ?: any;
 infoEventHandler ?: any;
 switchState ?: any;
}
export const ProjectTable : React.FC<Props> = ({ data ,switchState,infoEventHandler,activateEventHandler,editEventHandler ,deleteEventHandler,tableName,deactivateEventHandler}: Props) => {
  const dispatch = useAppDispatch()
  const [switchStates, setSwitchStates] = useState({});

  useEffect(() => {
   const initializeSwitchStates = () => {
    const initialState = {};
    for (let i = 0; i < data.length; i++) {
      initialState[data[i].id] = data[i].isActive || false;
    }
    return initialState;
  };
   setSwitchStates(initializeSwitchStates);
  }, [data,switchState]);

  // const columns = useMemo<MRT_ColumnDef<Person>[]>(
  //   //column definitions...
  //   () => [
  //     {
  //       accessorKey: 'projectName',
  //       header: 'Project Name',
  //     },
  //     {
  //       accessorKey: 'lead',
  //       header: 'Project lead',
  //     }
  //   ],
  //   [],
  //   //end
  // );
 const  handleChange = (id,row) => {
  setSwitchStates(prevStates => ({
    ...prevStates,
    [id]: !prevStates[id]
  })
);
  if(row.original.isActive === false){
    activateEventHandler(row)
  }
  else{
    deactivateEventHandler(row)
  }
}

  const resendMailHandler = (row) =>{
    let obj = {
      email: row?.email
    }
    dispatch<any>(resendEmailVerification(obj));
  }
  const handleDelete =(row) =>{
    deleteEventHandler(row)
  }
  const columns=useMemo(
    () => [
    {
    header : 'Project Name',
    size: 200,
    accessorFn: (row) => {
     return(<Row><Col style={{padding: '0'}}>{row.name}</Col><Col style={{padding: '0'}}> { tableName !== 'user' && <RiInformationFill id='deleteIcon' style={{cursor: 'pointer'}} onClick={() => handleInfo(row)}/>}</Col></Row>)
    }
   },
   {
    header : 'Project Lead',
    size: 150,
    accessorFn: (row) => {
     return(
       `${row?.projectLead?.user?.name}`
      )
    }
   },
   {
    header : 'Start Date',
    size: 150,
    accessorFn: (row) => {
     return(
       `${row?.startDate}`
      )
    }
   },
   {
    header : 'End Date',
    size: 150,
    accessorFn: (row) => {
     return(
       `${row?.endDate}`
      )
    }
   },
   {
    header : 'Allocated Hours',
    size: 100,
    accessorFn: (row) => {
     return(
       `${row?.allocatedHrs}`
      )
    }
   },
   {
    header : 'Logged Hours',
    size: 100,
    accessorFn: (row) => {
     return(
       `${row?.loggedHrs}`
      )
    }
   },
   {
    header : 'Remaining Hours',
    size: 100,
    accessorFn: (row) => {
      let Hrs = row?.allocatedHrs.concat(':00')
      let HrsFlag = row?.allocatedHrs.includes(":00")
      var time1=   HrsFlag ? `${row?.allocatedHrs}` : Hrs ;
      var time2= `${row?.loggedHrs}`;
      
      function strToMins(t) {
        var s = t.split(":");
        return Number(s[0]) * 60 + Number(s[1]);
      }
      
      function minsToStr(t) {
        return Math.trunc(t / 60)+':'+('00' + t % 60).slice(-2);
      }
      
      var result = minsToStr( strToMins(time1) - strToMins(time2) );
     return(
       `${result}`
      )
    }
   },
  ],
  [],
);
const columns1=useMemo(
  () => [
  {
  header : 'First Name',
  accessorFn: (row) => {
   return(
      `${row.firstName}`
   )
  }
 },
 {
  header : 'Last Name',
  accessorFn: (row) => {
   return(
    `${row.lastName}`
       )
  }
 },
 {
  header : 'Role',
  accessorFn: (row) => {
    return(
      `${row?.role}`
     )
  }
 },
 {
  header : 'Email',
  accessorFn: (row) => {
   return(
    `${row.email}`
   )
  }
 },
 {
  header : 'Email Verification',
  accessorFn: (row) => {
   return <div style={{height: '23px'}}>{row.verified === true ? <Row style={{width: 'fit-content'}}><Col style={{paddingRight: '0'}}><FaCheckCircle  id='checkIcon' color='green' /></Col><Col><p>Verified</p></Col></Row> :<Row style={{width: 'fit-content'}}><Col style={{paddingRight: '0', maxWidth: '17%'}}><MdCancel id='checkIcon' color='red' /></Col><Col style={{paddingRight: '0'}}><p>Not verified</p></Col><Col style={{maxWidth: '34%' , paddingLeft: '0'}}>
     <a data-tooltip-content='Send verification email again' data-tooltip-id="tooltip">
      <img src={require("./resend.png")} id="resendImg" onClick={() => resendMailHandler(row)} />
    </a>
    <Tooltip id="tooltip"style={{ backgroundColor: "#4775d1", color: "white", position:'fixed' }} /></Col></Row>
  }
  </div>
 }
}
],
[],
);
  const handleEdit = (row) =>{
    editEventHandler(row)
  }

 const handleInfo = (row) =>{
  infoEventHandler(row)
 }
  const table = useMaterialReactTable({
    columns : tableName === 'user' ? columns1 : columns,
    data,
    initialState: { columnVisibility: { id: false } },
    muiTableBodyRowProps: ({ row }) => ({
      //conditionally style expanded rows
      sx: {
        backgroundColor: tableName !== 'user' ? switchStates[row.original.id] ? 'white' : '#D8D8D8' : 'white',
      },
    }),
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 120, //if using layoutMode that is not 'semantic', the columns will not auto-size, so you need to set the size manually
        grow: false,
        },
    },
    enableTopToolbar: false,
    enableSorting:false,
    enableColumnActions:false,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
            <RiPencilFill  style={{cursor: switchStates[row.original.id] === true || tableName === 'user' ? 'pointer' : 'default' , width: '25px' , height: '25px'}} onClick={() => switchStates[row.original.id] === true || tableName === 'user' ? handleEdit(row) : ''}/>
            {tableName !== 'user'&&
            <div style={{marginTop: '3px'}}>
              <Switch checked={switchStates[row.original.id]}
                onChange={() => handleChange(row.original.id, row)} />
            </div>
               
            }
           
            <MdDelete id='deleteIcon' style={{cursor: 'pointer'}} onClick={() => handleDelete(row)}/>
       </Box>    
    ),
  });

  return (
    <>
     <MaterialReactTable
    table={table}
    />
    </>
   
  );
};
export default ProjectTable