import { ThunkAction} from "@reduxjs/toolkit";
import userSlice from "./UserSlice";
import { UserModel } from "../Models/UserModel";
import UserService from "../Service/UserService";
import { AnyAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { ProjectModel } from "../Models/ProjectModel";
import { WorklogModel } from "../Models/WorklogModel";
import { VerifiedUserModel } from "../Models/VerifiedUserModel";



export const userActions = userSlice.actions

export const fetchUser=():ThunkAction<void,RootState,unknown,AnyAction>=>{
  return async (dispatch) => {
    try {
      dispatch(userActions.setLoading())
      const response: UserModel[] = await UserService.getAllUsers();
      dispatch(userActions.setFinishLoading());
      dispatch(userActions.setUser(response))
      return response;
    } catch (error: any) {
      const message = error;
      dispatch(userActions.fetchUserError(message))
      return message;
    }
  };
}

// export const fetchUser = createAsyncThunk(
//    "user",
//    async (data, thunkApi) => {
//      try {
//        const response : UserModel[] = await UserService.getAllUsers();
//        return response;
//      } catch (error: any) {
//        const message = error.message;
//        return thunkApi.rejectWithValue(message);
//      }
//    }
//  );
 export const editUser = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
  return async (dispatch) => {
    try {
      dispatch(userActions.setLoading())
      const response: UserModel[] = await UserService.editUser(data);
      dispatch(userActions.setFinishLoading());
      dispatch(userActions.updateUser(response))
      return response;
    } catch (error: any) {
      const message = error;
      dispatch(userActions.editUserError(message))
      return message;
    }
  }
  };
  export const addUser = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: UserModel[] = await UserService.addUser(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.addUser(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.addUserError(message))
        return message;
      }
    }
  };
  export const deleteUser = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: UserModel[] = await UserService.deleteUser(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.deleteUser(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.deleteUserError(message))
        return message;
      }
    }
  };
  export const fetchProject=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.getAllProject();
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.fetchProject(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.fetchProjectError(message))
        return message;
      }
    }
  };
  export const fetchProjectList=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.getAllProjectList();
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.fetchProjectList(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.fetchProjectListError(message))
        return message;
      }
    }
  };
  export const fetchTeamLeadProject=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.getAllTeamLeadProject();
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.fetchTeamLeadProject(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.fetchProjectTeamLeadError(message))
        return message;
      }
    }
  };
  export const fetchUsersProject=(id):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.getAllUsersProject(id);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.fetchUsersProjectList(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.fetchUsersProjectListError(message))
        return message;
      }
    }
  };
  export const editProject = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.editProject(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.updateProject(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.editProjectError(message))
        return message;
      }
    }
  };
  export const addProject = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.addProject(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.addProject(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.addProjectError(message))
        return message;
      }
    }
  };
  export const deleteProject = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.deleteProject(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.deleteProject(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.deleteProjectError(message))
        return message;
      }
    }
  };
  export const deactivateProject = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.deactivateProject(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.deactivateProject(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.deactivateProjectError(message))
        return message;
      }
    }
  };
  export const activeProject = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.activeProject(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.activeProject(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.activeProjectError(message))
        return message;
      }
    }
  };
  export const fetchWorklog=(params: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: WorklogModel[] = await UserService.getAllWorklog(params);
        dispatch(userActions.fetchWorklog(response))
        dispatch(userActions.setFinishLoading());
       
        return response;
      } catch (error: any){
        const message = error;
        dispatch(userActions.fetchWorklogError(message))
        return message;
      }
    }
  };
  export const fetchUserWorklog=(params: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: WorklogModel[] = await UserService.getUserAllWorklog(params);
        dispatch(userActions.fetchUserWorklog(response))
        dispatch(userActions.setFinishLoading());
       
        return response;
      } catch (error: any){
        const message = error;
        dispatch(userActions.fetchWorklogError(message))
        return message;
      }
    }
  };
  export const fetchTeamLeadWorklog=(params: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: WorklogModel[] = await UserService.getTeamLeadAllWorklog(params);
        dispatch(userActions.fetchTeamLeadWorklog(response))
        dispatch(userActions.setFinishLoading());
       
        return response;
      } catch (error: any){
        const message = error;
        dispatch(userActions.fetchWorklogError(message))
        return message;
      }
    }
  };
  export const editWorklog = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: WorklogModel[] = await UserService.editWorklog(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.updateWorklog(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.updateWorklogError(message))
        return message;
      }
    }
  };
  export const addWorklog = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: WorklogModel[] = await UserService.addWorklog(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.addWorklog(response))
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.addWorklogError(message))
        return message;
      }
    }
  };
  export const deleteWorklog = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: WorklogModel[] = await UserService.deleteWorklog(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.deleteWorklog(response))
        return response;
      } catch (error: any) {
        const message = error;
      
        dispatch(userActions.deleteWorklogError(message))
        return message;
      }
    }
  };
  export const userVerification = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.userVerification(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.verifyUser())
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.userVerificationError(message))
        return message;
      }
    }
  };
  export const resendEmailVerification = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.resendEmailVerification(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.resendEmail())
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.userVerificationError(message))
        return message;
      }
    }
  };
  export const forgotPassword = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.forgotPassword(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.forgotPassword())
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.forgotPassowrdError(message))
        return message;
      }
    }
  };
  export const resetPassword = (data : any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: ProjectModel[] = await UserService.resetPassword(data);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.resetPassword())
        return response;
      } catch (error: any) {
        const message = error;
        dispatch(userActions.resetPasswordError(message))
        return message;
      }
    }
  };
  export const fetchWorklogById=(params: any):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response = await UserService.getWorklogById(params);
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.fetchWorklogById(response))
        return response;
      } catch (error: any){
        const message = error;
        dispatch(userActions.getWorklogError(message))
        return message;
      }
    }
  };
  export const fetchVerifiedUser=():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async (dispatch) => {
      try {
        dispatch(userActions.setLoading())
        const response: VerifiedUserModel[] = await UserService.getVerifiedUsers();
        dispatch(userActions.setFinishLoading());
        dispatch(userActions.setVerifiedUser(response))
        return response;
      } catch (error: any) {
        const message = error.message;
        dispatch(userActions.getVerifiedUserError(message))
        return message;
      }
     }
    };
    export const fetchVerifiedTeamLeadUser=():ThunkAction<void,RootState,unknown,AnyAction>=>{
      return async (dispatch) => {
        try {
          dispatch(userActions.setLoading())
          const response: VerifiedUserModel[] = await UserService.getVerifiedTeamLeadUsers();
          dispatch(userActions.setFinishLoading());
          dispatch(userActions.setVerifiedTeamLeadUser(response))
          return response;
        } catch (error: any) {
          const message = error.message;
          dispatch(userActions.getVerifiedTeamLeadUserError(message))
          return message;
        }
       }
      };