import React from 'react'
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import { ManageWorklog } from './pages/ManageWorklog'
import { Login } from './pages/Login'
import { ManageProject } from './pages/ManageProject'
import { ManageUser } from './pages/ManageUser'
import { ConfirmComp } from './pages/ConfirmComp'
import { ResetPassword } from './pages/ResetPassword'
import RoutesComp from './Routes'

const App: React.FC = () => {
  const PrivateRoute = () => {
    const auth = localStorage.getItem("token"); // determine if authorized, from context or however you're doing it
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth !== 'null' ?  <RoutesComp />  : <Navigate to="/login" />;
} 
  return (
    <BrowserRouter>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/login" element={<Login/>} />
        <Route  path='/' element={<PrivateRoute/>}>
          <Route path="/manage_worklog" element={<ManageWorklog/>} />
          <Route path="/manage_project" element={<ManageProject />} />
          <Route path="/manage_user" element={ <ManageUser /> } />
          </Route>
        
        <Route path="/email/confirm/:uniqueId" element={<ConfirmComp/> }/>
        <Route path="/forgotPassword/:ID" element={<ResetPassword/> }/>
        
        </Routes>
    </BrowserRouter>
  )
}

export default App
