import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { login } from "./LoginAction";
import { User } from "../Models/User";

export interface initialUserState{
    user : User[]
    loading : boolean
    error : string
    isUser : boolean
}
const initialUserState : initialUserState ={
    user: [],
    loading: false,
    error: '',
    isUser : false
}

const loginSlice = createSlice({
    name: 'login',
    initialState: initialUserState,
    reducers:{
      logout(state){
        localStorage.setItem('user',null)
        localStorage.setItem('token', null)
        return {
          ...state,
          isUser : false

        }
      },
        // setUser(state,action: PayloadAction<UserModel[]>){
        //     state.userList = action.payload;
        // },
        // updateUser(state,action: PayloadAction<UserModel[]>){
        //     state.userList = action.payload;
        // }
    },
    extraReducers(builder) {
        builder.addCase(login.pending,(state,action) =>{
            state.loading = true;
            state.isUser = false;
            state.error = '';
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<User[] | any>) => {
        sessionStorage.setItem('selectedRole', 'user'); 
        localStorage.setItem('user', JSON.stringify(action.payload));
        localStorage.setItem('token', JSON.stringify(action.payload.token));
        state.loading = false;
        state.user = action.payload;
        state.isUser = true;
        state.error = '';
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        let code = action.payload.response?.status
        switch (code) {
          case 406:
            state.error = 'Incorrect password';
              break;
          case 400:
            state.error = 'User not found';
              break;
          case 401:
            state.error = 'Unauthorized';
              break;
          case 404:
            state.error = 'Incorrect email address';
              break;
          case 403:
            state.error = 'Your email address is not verified. Please check your inbox to complete the verification or contact an administrator';
              break;
          case 200:
            state.error = null;
              break;
          case 0:
          case -1:
            state.error = null;
              break;
          default:
            state.error = 'Unable to log in, please try again';
              break;
      }
      })
    }
  })

export default loginSlice;