import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { addUser, editUser } from '../store/UserAction';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import './ManageUser.css'
type Props = {
    formType : string;
    rowData ?: any;
    addTableData ?: any;
    closeModal ?: any;
  }
  type FormDataProps ={
    firstName ?: any;
    lastName : string;
    role ?: any;
    email : string;
    id : number;
    password ?: any;
  }
export const AddUser: React.FC<Props>  = ({formType,rowData,addTableData,closeModal}: Props) =>  {
const [firstNameValid,setFirstNameValid] = useState(false)
const [lastNameValid,setLastnameValid] = useState(false)
const [roleValid, setRoleValid] = useState(false)
const [emailValid, setEmailValid] = useState(false);
const [passwordValid, setPasswordValid] = useState(false);
const [data, setData] =useState<FormDataProps>(rowData);
const [roleData,setRole] = useState([])
const loading = useAppSelector(state=>state.user.loading);
const dispatch=useAppDispatch()

useEffect(()=>{
  let array = data?.role?.split(" ");
  let myArray = array?.map((str, index) => ({ name: str, id: index + 1 }));
  setRole(myArray)
},[])

const onSubmit = (e) =>{
  e.preventDefault()
 var re = /\S+@\S+\.\S+/;
 let flag = false
 if(data?.firstName === undefined){
  flag = true
  setFirstNameValid(true)
}
if((data?.lastName == undefined)){
  flag = true
  setLastnameValid(true)
}
if((data?.email == undefined) || (!re.test(data?.email))){
  flag = true
  setEmailValid(true)
}
if((roleData === undefined) || (data?.role == undefined)){
  flag = true
  setRoleValid(true)
}
if((formType !== 'edit') && (data?.password === undefined)){
  flag = true
  setPasswordValid(true)
}
if(!flag){
  // addTableData()
  if(formType === 'edit'){
    dispatch<any>(editUser(data));
  }
  else{
    dispatch<any>(addUser(data));
  }
}
}

const handleInputChange = (e) =>{
 const { name, value } = e.target;
 if(e.target.name === 'firstName' && e.target.value !== ''){
  setFirstNameValid(false)
 }
 if(e.target.name === 'lastName'  && e.target.value !== ''){
  setLastnameValid(false)
 }
 if(e.target.name === 'email'  && e.target.value !== ''){
  setEmailValid(false)
 }
 if(e.target.name === 'password'  && e.target.value !== ''){
  setPasswordValid(false)
 }
 setData({...data,  [name]: value,})
}
const handleRoleChange = (e) =>{
  if(e.length === 0){
    setData({...data, role: ''})
    setRole([])
  }
  else{
    setData({...data, role: e[0].name})
    setRole(e)
  }
  setRoleValid(false)
}
const role =[{id: 1, name: 'admin'},{id:2, name:'user'}]
return (
    <Fragment>
       <Form  onSubmit={onSubmit}>
            <FormGroup>
                <Label for="exampleEmail">
                 First Name
                </Label>
                <Input
                invalid={firstNameValid}
                value={data?.firstName}
                id="exampleEmail"
                name="firstName"
                placeholder="Enter First Name"
                type="text"
                onChange={handleInputChange}
                />
               <FormFeedback>
                    Please enter first name
                </FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="examplePassword">
                 Last Name
                </Label>
                <Input
                invalid={lastNameValid}
                value={data?.lastName}
                id="exampleEmail"
                name="lastName"
                placeholder="Enter Last Name"
                type="text"
                onChange={handleInputChange}
                />
              <FormFeedback>
                  Please enter last name
              </FormFeedback>
            </FormGroup>
            <FormGroup>
            <Label for="examplePassword">
                 User role
                </Label>
                <Typeahead
                  isInvalid={roleValid}
                  id="basic-typeahead-single"
                  className='dropdownComp'
                  labelKey="name"
                  onChange={handleRoleChange}
                  options={role}
                  clearButton
                  placeholder="Select User's Role"
                  selected={roleData}
                />
               <FormFeedback>
                    Please select user's role
                </FormFeedback>
            </FormGroup>
            <FormGroup>
            <Label for="examplePassword">
                 Email
                </Label>
                <Input
                value={data?.email}
                invalid={emailValid}
                name="email"
                placeholder="Enter Email Id"
                type="text"
                onChange={handleInputChange}
                />
               <FormFeedback>
                    Please enter proper email id
                </FormFeedback>
            </FormGroup>
             {formType === 'add' &&
                <FormGroup>
               <Label for="examplePassword">
               Password
              </Label>
              <Input
              value={data?.password}
              invalid={passwordValid}
              id="exampleEmail"
              name="password"
              placeholder="Enter Password"
              autoComplete='new-password'
              type="password"
              onChange={handleInputChange}
              />
                  <FormFeedback>
                        Please enter password
                    </FormFeedback>
                </FormGroup>
              }
           
            <hr />
            <div className='createProjectBtn'>
            <Button color="secondary" onClick = {closeModal} style={{marginRight: '10px'}}>Cancel</Button>
            <Button color="primary" type='submit'>{loading ? 'Saving data...' : 'Save'}</Button>
            </div>
            
        </Form>
    </Fragment>
  )
}

