import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom'
import { FaHamburger } from "react-icons/fa";
import CompLogo from "../pages/MeConIT300x300 (2).png";
import { IoLogOutOutline } from "react-icons/io5";
import { FaCircleUser } from "react-icons/fa6";
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch} from '../../src/hooks/redux-hooks';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn
} from "./NavbarElements";
import './Navbar.css';
import userSlice from '../store/UserSlice';
import loginSlice from '../store/LoginSlice';
import { MDBBtn, MDBCollapse } from 'mdb-react-ui-kit';

export const Navbar: React.FC = () => {
  const [showNavbar, setShowNavbar] = useState(false)
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('user'))
  const dispatch=useAppDispatch()
  const [showNavExternal, setShowNavExternal] = useState(false);

 useEffect(()=>{
  if((!localStorage.getItem("token")) ||(localStorage.getItem("token") == null)){
    navigate('/login')
  }
 }, [])
 const handleLogout = () =>{
  navigate('/login')
  dispatch(userSlice.actions.reset())
  dispatch(loginSlice.actions.logout())
 }
 const handleClick = () =>{
  dispatch(userSlice.actions.reset())
 }
 const handleToggle = () =>{
  setShowNavExternal(!showNavExternal)
 }
  return (
    <>
            <Nav id='header'>
                <Bars   onClick={handleToggle }>
                  </Bars>
 
                <NavMenu>
                  <NavBtn style={{'display': 'flex !important'}}> 
                    <div className="logo form-inline">
          <img  src={CompLogo} style={{marginBottom : '10px'}} alt="Company Logo"></img>
          </div></NavBtn>
          { JSON.parse(localStorage.getItem("user"))?.role === 'admin' || 'user'|| 'Team Lead' ?
           JSON.parse(localStorage.getItem("user"))?.role === 'admin' ?
                    <>
                       <NavLink to="/manage_project">
                          Manage Project
                       </NavLink>
                       <NavLink to="/manage_user">
                         Manage User
                       </NavLink>
                       <NavLink to="/manage_worklog" onClick={handleClick}>
                      Manage Worklog
                    </NavLink></>
                    :
                    JSON.parse(localStorage.getItem("user"))?.role === 'Team Lead' ? 
                    <>
                       <NavLink to="/manage_project">
                          Manage Project
                       </NavLink>
                       <NavLink to="/manage_worklog" onClick={handleClick}>
                      Manage Worklog
                    </NavLink></> :
                    <NavLink to="/manage_worklog">
                    Manage Worklog
                  </NavLink>
                     : <Navigate to="/" /> }
                    
                </NavMenu>
                <NavBtn id="abc">
                <FaCircleUser  id='userProfileIcon' />
                    <ul style={{listStyleType: 'none',marginTop: '18px',paddingLeft: '15px'}}>
                 <li style={{color:'#FFFFFF', fontSize: '16px',  fontWeight: 'bold'}}>{`${user?.firstName +" "+user?.lastName}`}</li>
                  <li style={{color:'#F8F8F8', fontSize: '14px',fontWeight: 'semibold'}}>{user?.role}</li>
                   </ul>
                   <IoLogOutOutline onClick={handleLogout} style={{color: '#ffffff', width:'50px', height:'50px', paddingLeft:'10px', cursor : 'pointer'}}/>
                </NavBtn>
            </Nav>
            <MDBCollapse open={showNavExternal}>
        <div className='bg-light shadow-3 p-4'>
        { JSON.parse(localStorage.getItem("user"))?.role === 'admin' || 'user' || 'Team Lead' ?
           JSON.parse(localStorage.getItem("user"))?.role === 'admin' ?
                    <>
                      <NavBtn style={{backgroundColor: '#00ACE8', justifyContent: 'center' , color: 'white', marginRight: '0px'}}>
                <FaCircleUser id='userProfileIcon'  />
                    <ul style={{listStyleType: 'none',marginTop: '18px',paddingLeft: '15px'}}>
                 <li style={{ fontSize: '16px',  fontWeight: 'bold'}}>{`${user?.firstName +" "+user?.lastName}`}</li>
                  <li style={{fontSize: '14px',fontWeight: 'semibold'}}>{user?.role}</li>
                   </ul>
                </NavBtn>
                       <MDBBtn  block className='border-bottom m-0' color='link' onClick={() => navigate('/manage_project')}>
                          Manage Project
                       </MDBBtn>
                       <MDBBtn  block className='border-bottom m-0' color='link' onClick={() => navigate('/manage_user')}>
                         Manage User
                       </MDBBtn>
                       <MDBBtn  block className='border-bottom m-0' color='link' onClick={() => navigate('/manage_worklog')} to="/manage_worklog" >
                      Manage Worklog
                    </MDBBtn>
                    <MDBBtn  block className='border-bottom m-0' color='link' onClick={() => navigate('/login')}>
                    Logout
                  </MDBBtn></>
                    :
                    JSON.parse(localStorage.getItem("user"))?.role === 'Team Lead' ? 
                    <>
                       <MDBBtn  block className='border-bottom m-0' color='link' onClick={() => navigate('/manage_project')}>
                          Manage Project
                       </MDBBtn>
                    <MDBBtn block className='border-bottom m-0' color='link' onClick={() => navigate('/manage_worklog')}>
                        Manage Worklog
                     </MDBBtn><MDBBtn block className='border-bottom m-0' color='link' onClick={() => navigate('/login')}>
                       Logout
                     </MDBBtn></> :
                     <>
                       <MDBBtn block className='border-bottom m-0' color='link' onClick={() => navigate('/manage_worklog')}>
                        Manage Worklog
                     </MDBBtn><MDBBtn block className='border-bottom m-0' color='link' onClick={() => navigate('/login')}>
                       Logout
                     </MDBBtn>
                     </>
                     : <Navigate to="/" /> }
                    
        </div>
      </MDBCollapse>
        </>
   )
        {/* <Row>
        <div className="xyz">
          <Col>
          <div className="logo form-inline">
          <img  src={CompLogo}  alt="Company Logo"></img>
          </div>
          <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            {localStorage.getItem('email') === 'admin@test.com' && 
            <li>
              <NavLink to="/manage_project">Manage Project</NavLink>
            </li>
            }
            <li>
              <NavLink to="/manage_worklog">Manage Worklog</NavLink>
            </li>
            <li>
              <NavLink to="/">Logout</NavLink>
            </li>
          </ul>
        </div>
          </Col>
          <Col style={{display: 'flex', justifyContent:'end'}}>
          <div>
          <ul style={{listStyleType: 'none'}}>
          <li >Priyanka Kalbere</li>
          <li style={{paddingLeft: '40px', color:'#d9d9d9', fontSize: '14px'}}>Admin</li>
          </ul>
         
        </div>
          </Col>
       
       {/* <Row>
        <Col><p>Me</p></Col><Col><p>Con</p></Col><Col><p>IT</p></Col>
       
        </Row>  */}
    
        {/* <div className="menu-icon" onClick={handleShowNavbar}>
          <FaHamburger />
        </div>
        
        */}
}

