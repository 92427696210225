import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './ProjectListComp.css';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import {fetchVerifiedUser,fetchVerifiedTeamLeadUser } from '../../store/UserAction';

type Props = {
  onUserSelection ?: any;
  onMenuClose ?: any;
  userDataTeamLead ?: any;
}

export const UserListComp: React.FC<Props>  = ({onUserSelection,onMenuClose,userDataTeamLead}: Props)=> {
  const dispatch= useAppDispatch()
  const getUserFlag = useAppSelector(state=>state.user.getVerifiedUsers);
  const getVerifiedTeamLeadUsers = useAppSelector(state=>state.user.getVerifiedTeamLeadUsers);
  const user = useAppSelector(state=>state.user.verifiedUsers)
  const verifiedTeamLeadUsers = useAppSelector(state=>state.user.verifiedTeamLeadUsers)
  const [userList, setUserList] = useState([])
  const selectRef = useRef(null)
  const [btnFlag,setBtnFlag] = useState(true)
  
  const onChange = checkedList => {
    if(checkedList.length !== 0 ){
      setBtnFlag(false)
    }
    else{
      setBtnFlag(true)
    }
    const res = checkedList.map(item => (item))
    onUserSelection(res)
  }
  useEffect(() =>{
    
    if(getUserFlag === true && JSON.parse(localStorage.getItem('user'))?.role === 'admin'){
      const res = user.map(item => ({label: item.name,value : item.id }))
        setUserList(res)
    }
    if(getVerifiedTeamLeadUsers === true && JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead'){
      const res = verifiedTeamLeadUsers.map(item => ({label: item.name,value : item.id }))
      userDataTeamLead(res)
      setUserList(res)
    }
    if(JSON.parse(localStorage.getItem('user'))?.role === 'admin'){
      const res = user.map(item => ({label: item.name,value : item.id }))
      dispatch<any>(fetchVerifiedUser());
      setUserList(res)
    }
    else{
      const res = verifiedTeamLeadUsers.map(item => ({label: item.name,value : item.id }))
      dispatch<any>(fetchVerifiedTeamLeadUser());
      setUserList(res)
    }
 
  },[dispatch,getUserFlag,getVerifiedTeamLeadUsers])

const onBtnClick = () =>{
  onMenuClose(true)
  selectRef.current.state.isOpen = false
}

// const options = [
//   { label: 'Project 1', value: 1},
//   { label: 'Project 2', value: 2},
//   { label: 'Project 3', value: 3},
//   { label: 'Project 3', value: 4},
//   { label: 'Project 3', value: 5},
//   { label: 'Project 3', value: 6},
//   { label: 'Project 3', value: 7},
//   { label: 'Project 3', value: 8},
//   { label: 'Project 3', value: 9},
//   { label: 'Project 3', value: 10},
// ];

const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) =>
  !isDisabled ? (
      <div ref={innerRef} {...innerProps}>
          {children}
          <button
              className="btn btn-info btn-sm btn-block"
              onClick={onBtnClick}
            >Apply</button>
      </div>
  ) : null;

  return (
    
    <Fragment>
        <ReactMultiSelectCheckboxes 
         ref={selectRef}
         options={userList} components={{Menu:CustomMenu,IndicatorSeparator: () => null}}
        onChange={onChange} placeholderButtonLabel="Select User" 
       
         >
        
            
         </ReactMultiSelectCheckboxes>
         {/* <MultiSelect {...props} /> */}
         {/* <Select
      closeMenuOnSelect={false}
      components={{ ValueContainer,MultiValue,Menu:CustomMenu,Option}}
      isMulti
      options={projectList}
      menuIsOpen={true}
    /> */}

    </Fragment>
  )
}
